import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent, PlatformLocation } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { ApplicationService } from '../../_services/application.service';
import { AppVersion } from '../../_interfaces/app-version';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html'
})

export class AdminLayoutComponent implements OnInit, OnDestroy {
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    timoutCountdown:number = 0;
    private _router: Subscription;
    private serviceSubscription: Subscription;
    // url: string;
    url: string;
    location: Location;
    private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  public loading = false;
  public appVersion: AppVersion; 
    public mainPanel;
    @ViewChild('sidebar') sidebar;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;
    copyrightToYear: string;

    constructor(private router: Router, location: Location, private appservice: ApplicationService, private idle: Idle, private keepalive: Keepalive, public auth: AuthService) {
        this.location = location;
        this.idle = idle;
        this.keepalive = keepalive;
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(this.auth.getClientTimeout() * 60);
        //idle.setIdle(5);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(60);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            { $('#timeoutModal').modal('hide'); }
            this.auth.logout()
        });
        idle.onIdleStart.subscribe(() => { $('#timeoutModal').modal('show'); });
        idle.onTimeoutWarning.subscribe((countdown) => {
            this.timoutCountdown = countdown;
            this.idleState = 'You will time out in ' + countdown + ' seconds!'
        });
        

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => { $('#timeoutModal').modal('hide'); });

        this.reset();
      this.serviceSubscription = this.appservice.listener.subscribe(() => {
        this.loading = true; 
        this.appservice.GetAppVersion().subscribe(result => {
          this.appVersion = result as AppVersion;
          this.loading = false;
          $('#logoInfoModal').modal('show');

        });
      });
        //OD-173 update copyright to year
        this.copyrightToYear = new Date().getFullYear().toString();
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if ((event.target.innerWidth > 991) && (this.mainPanel.style.position === 'fixed')) {
            this.mainPanel.removeAttribute('style');
        };
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    ngOnInit() {
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        this.mainPanel = elemMainPanel;
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url !== this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (event instanceof NavigationEnd) {
                if (event.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            elemMainPanel.scrollTop = 0;
            elemSidebar.scrollTop = 0;
        });
        const html = document.getElementsByTagName('html')[0];
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            let ps = new PerfectScrollbar(elemMainPanel);
            ps = new PerfectScrollbar(elemSidebar);
            html.classList.add('perfect-scrollbar-on');
        } else {
            html.classList.add('perfect-scrollbar-off');
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            this.navbar.sidebarClose();
        });
       
        
    }
    public isMap() {
        if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
            return true;
        } else {
            return false;
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    ngOnDestroy(): void {
        this.serviceSubscription.unsubscribe();
    }

}
