import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { ApplicationService } from '../_services/application.service';

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
  extraicon?: boolean;
  extraiconpath?: string;
  extraiconqueryparams?: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items

export var ROUTES: RouteInfo[] = [
    
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})





export class SidebarComponent implements OnInit, AfterViewInit {

    public menuItems: any[];
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    constructor(private applicationService: ApplicationService) {
    }

    showInfo() {
        this.applicationService.sendLogoInfo();
    }
   

  ngOnInit() {
        this.applicationService.LoadUserRoles().subscribe(result => {
            ROUTES.splice(0, ROUTES.length);
            ROUTES.push({
                path: '/dashboard',
                title: 'Dashboard',
                type: 'link',
                icontype: 'fa fa-chart-line'
            });
            //if (!WebContext.Current.User.IsInRole("BGT BUDGET QUERY") && !WebContext.Current.User.IsInRole("BGT BUDGET ENTRY") && !WebContext.Current.User.IsSchemaOwner)
            if (this.applicationService.IsInRole("BGT BUDGET QUERY") || this.applicationService.IsInRole("BGT BUDGET ENTRY") || this.applicationService.IsSchemaOwner()) {
                ROUTES.push({
                    path: '/my-accounts',
                    title: 'My Accounts',
                    type: 'link',
                    icontype: 'fa fa-archive'
                });
            }

            if (this.applicationService.IsInRole("BGT PURCHASE REQUESTS") || this.applicationService.IsSchemaOwner()) {
                ROUTES.push({
                    path: '/purchase-requests',
                    title: 'Purchase Requests',
                    type: 'link',
                    icontype: 'fa fa-pen-alt',
                  extraicon: true,
                  extraiconpath: '/purchase-requests',
                  extraiconqueryparams:'id: 0'
                });
            }
            if (this.applicationService.IsInRole("BGT FX CREATE ASSETS") || this.applicationService.IsSchemaOwner()) {
                ROUTES.push({
                    path: '/assets',
                    title: 'Assets',
                    type: 'link',
                    icontype: 'fa fa-cubes'
                });
          }
          if (this.applicationService.IsInRole("BGT DEPARTMENT ACTIVITY REPORT") ||
            this.applicationService.IsInRole("BGT PRINT PURCHASE REQUESTS") ||
            this.applicationService.IsInRole("BGT PURCHASE REQUESTS LIST") ||this.applicationService.IsSchemaOwner()) {
            ROUTES.push({
              path: '/budget-reports',
              title: 'Budget Reports',
              type: 'link',
              icontype: 'fas fa-clipboard-list'
            });
          }
            //ROUTES.push({
            //  path: '/settings',
            //  title: 'Settings',
            //  type: 'link',
            //  icontype: 'fa fa-cog'
            //});
            ROUTES.push({
                path: '/pages/logout',
                title: 'Logout',
                type: 'link',
                icontype: 'fa fa-sign-out-alt'
            });
            this.menuItems = ROUTES.filter(menuItem => menuItem);
        });

    }
    ngAfterViewInit() {
    }
}


