import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor() { }

    handleError(error) {
        if (error.hasOwnProperty("message") && (this.ignoreList.includes(error.message) || this.ignoreList.some(x => error.message.indexOf(x)>-1))) {
            return;
        }
        console.log(error);
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                return;
            }
        }

        this.showNotification(error);
    }

    ignoreList: string[] = [
        "Cannot read property 'clientWidth' of null", "ViewDestroyedError: Attempt to use a destroyed view: detectChanges", "Http failure during parsing for",
        "Cannot read property 'getContext' of null", "ExpressionChangedAfterItHasBeenCheckedError", "Cannot read properties of null (reading 'clientWidth')"
    ];


    showNotification(error) {


        $.notify({
            icon: "ti-gift",
            message: "The system has encountered an error.<br/>There can be many causes for this.<br/><br/>If the problem persists, try logging out (if possible), closing your browser, and then logging back in. If that does not solve the problem, please make a note of the message in the box below and contact Senior Systems Product Support.<br/><br/>" + error.message
        }, {
                type: 'danger',
                timer: 0,
                delay: 0,

                placement: {
                    from: 'top',
                    align: 'center'
                },
                template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
            });
    }

}
