import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from './auth/auth-guard.service';


export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'dashboard',
  pathMatch: 'full',
}, {
  path: '',
  component: AdminLayoutComponent,
  children: [{
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
    ,canActivate: [AuthGuardService]
  }, {
    path: 'my-accounts',
      loadChildren: './my-accounts/my-accounts.module#MyAccountsModule'
      , canActivate: [AuthGuardService]
  }, {
    path: 'purchase-requests',
      loadChildren: './purchase-requests/purchase-requests.module#PurchaseRequestsModule'
      , canActivate: [AuthGuardService]
  }, {
    path: 'assets',
      loadChildren: './assets/assets.module#AssetsModule'
      , canActivate: [AuthGuardService]
  }, {
    path: 'budget-reports',
      loadChildren: './budget-reports/budget-reports.module#BudgetReportsModule'
      , canActivate: [AuthGuardService]
  }, {
    path: 'settings',
      loadChildren: './settings/settings.module#SettingsModule'
      , canActivate: [AuthGuardService]
  }]
}, {
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: 'pages',
    loadChildren: './pages/pages.module#PagesModule'
  }]
}
];
