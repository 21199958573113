import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { ReqMaster } from '../_interfaces/req-master';
import { PurchaseRequestBudget } from '../_interfaces/purchase-request-budget';
import { AdmPOShipTo } from '../_interfaces/adm-poship-to';
import { ApShipVia } from '../_interfaces/ap-ship-via';
import { ApPOType } from '../_interfaces/ap-potype';
import { User } from '../_interfaces/user';
import { PoMaster } from '../_interfaces/po-master';
import { ReportData } from '../_interfaces/report-data';
import { PoReceipt } from '../_interfaces/po-receipt';
import { TabName } from '../_interfaces/tab-name';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({
    providedIn: 'root'
})
export class PurchaseRequestsServiceService {

    constructor(private http: HttpClient, private repository: RepositoryService, private authService: AuthService) { }


    public GetPurchaseRequest(reqNumber: number): Observable<ReqMaster> {
        let params = new HttpParams().set("reqNo", reqNumber.toString());

        const obsRet = new Observable<ReqMaster>(observer => {
            this.repository.getData('api/PurchaseRequests/' + reqNumber.toString()).subscribe(result => {
                var m = result as ReqMaster;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    ///api/PurchaseRequests/BudgetDataForPurchaseRequest?reqNo=36&accounts=14275107&currentGLfiscalMonth=2015&currentGLfiscalYear=6&nextFiscalYear=false
    public GetPurchaseRequestBudget(reqNo: number, accounts: string[], currentGLfiscalYear: number, currentGLfiscalMonth: number, nextFiscalYear: boolean): Observable<PurchaseRequestBudget[]> {
        let params = new HttpParams().set("reqNo", reqNo.toString())
            .set("currentGLfiscalYear", currentGLfiscalYear.toString())
            .set("nextFiscalYear", nextFiscalYear.toString())
            .set("currentGLfiscalMonth", currentGLfiscalMonth.toString())
            .set("accounts", accounts.toString());
      
        const obsRet = new Observable<PurchaseRequestBudget[]>(observer => {
            this.repository.getDataWithParams('api/PurchaseRequests/BudgetDataForPurchaseRequest', params).subscribe(result => {
                var m = result as PurchaseRequestBudget[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    //curl -X GET "https://radiusdemo2.senior-systems.com/api/PurchaseRequests/ShipTo" -H "accept: text/plain" -H "Authorization: aaa"
    public GetShipTo(): Observable<AdmPOShipTo[]> {
        const obsRet = new Observable<AdmPOShipTo[]>(observer => {
            this.repository.getData('api/PurchaseRequests/ShipTo').subscribe(result => {
                var m = result as AdmPOShipTo[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    //curl -X GET "https://radiusdemo2.senior-systems.com/api/PurchaseRequests/ShipVia" -H "accept: text/plain" -H "Authorization: aaa"
    public GetShipVia(): Observable<ApShipVia[]> {
        const obsRet = new Observable<ApShipVia[]>(observer => {
            this.repository.getData('api/PurchaseRequests/ShipVia').subscribe(result => {
                var m = result as ApShipVia[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    //curl -X GET "https://radiusdemo2.senior-systems.com/api/PurchaseRequests/POTypes" - H "accept: text/plain" - H "Authorization: aaa"
    public GetPOTypes(): Observable<ApPOType[]> {
        const obsRet = new Observable<ApPOType[]>(observer => {
            this.repository.getData('api/PurchaseRequests/POTypes').subscribe(result => {
                var m = result as ApPOType[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    //curl -X GET "https://radiusdemo2.senior-systems.com/api/PurchaseRequests/BuyerUsers" -H "accept: text/plain" -H "Authorization: aaa"
    public GetBuyerUsers(): Observable<User[]> {
        const obsRet = new Observable<User[]>(observer => {
            this.repository.getData('api/PurchaseRequests/BuyerUsers').subscribe(result => {
                var m = result as User[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public GetSendToUsers(userId: string, status: string, extraUserID: string, glAccountsArray?: string[]): Observable<User[]> {
        
        var glAccounts = "";
        var count = 0;        
        
        if (glAccountsArray != null && glAccountsArray.length > 0) {
            glAccountsArray.forEach(function (value) {
                glAccounts = glAccounts + "'" + value + "'";
                count++;
                if (count < glAccountsArray.length)
                    glAccounts = glAccounts + ",";
            })
        }

        let params = new HttpParams()
            .set("userId", userId)
            .set("status", status)
            .set("extraUserID", extraUserID)
            .set("glAccounts", glAccounts)
        
        //if (glAccounts != null) params.append("glAccounts", glAccounts);
        const obsRet = new Observable<User[]>(observer => {
            this.repository.getDataWithParams('api/PurchaseRequests/SendToUsers', params).subscribe(result => {
                var m = result as User[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public GetRequestedByUsers(): Observable<User[]> {
        const obsRet = new Observable<User[]>(observer => {
            this.repository.getData('api/PurchaseRequests/RequestedByUsers').subscribe(result => {
                var m = result as User[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public GetTabNameForUser(userId: string): Observable<TabName> {
        const obsRet = new Observable<TabName>(observer => {
            this.repository.getData('api/TabName/' + userId).subscribe(result => {
                var m = result as TabName;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public UpdateTabNameForUser(tabName: TabName): Observable<boolean> {
        const obsRet = new Observable<boolean>(observer => {
            this.repository.update('api/TabName', tabName).subscribe(result => {
                var m = result as boolean;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public UpdateRequest(request: ReqMaster): Observable<boolean> {
        const obsRet = new Observable<boolean>(observer => {
            this.repository.update('api/PurchaseRequests', request).subscribe(result => {
                var m = result as boolean;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public CreateRequest(request: ReqMaster): Observable<number> {
        const obsRet = new Observable<number>(observer => {
            this.repository.create('api/PurchaseRequests', request).subscribe(result => {
                var m = result as number;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public DeleteRequest(requestNo: number): Observable<boolean> {
        const obsRet = new Observable<boolean>(observer => {
            this.repository.delete('api/PurchaseRequests/' + requestNo.toString()).subscribe(result => {
                var m = result as boolean;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public ReqNumberExists(requestNoShow: string): Observable<boolean> {
        let params = new HttpParams().set("reqNoShow", requestNoShow);
        const obsRet = new Observable<boolean>(observer => {
            this.repository.getDataWithParams('api/PurchaseRequests/ReqNumberExists', params).subscribe(result => {
                var m = result as boolean;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }


    public GetPurchaseOrder(poNo: string): Observable<PoMaster> {

        const obsRet = new Observable<PoMaster>(observer => {
            this.repository.getData('api/PurchaseRequests/PurchaseOrder/' + poNo.toString()).subscribe(result => {
                var m = result as PoMaster;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }


    public GetAmountLimit(userId: string, accts: string[]): Observable<number> {
        let params = new HttpParams().set("userId", userId)
            .set("accounts", accts.toString());
      
        const obsRet = new Observable<number>(observer => {
            this.repository.getDataWithParams('api/PurchaseRequests/GetAmountLimit', params).subscribe(result => {
                var m = result as number;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }


    public AccountRequiresMultipleSupervisors(userId: string, accts: string[]): Observable<string[]> {
        let params = new HttpParams().set("userId", userId)
            .set("accounts", accts.toString());
       
        const obsRet = new Observable<string[]>(observer => {
            this.repository.getDataWithParams('api/PurchaseRequests/AccountRequiresMultipleSupervisors', params).subscribe(result => {
                var m = result as string[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public GetNextReqNoShow(): Observable<string> {

        const obsRet = new Observable<string>(observer => {
            this.repository.getData('api/PurchaseRequests/NextPRNoShow').subscribe(result => {
                var m = result as string;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public GetReportDataForRequest(reqNo: number): Observable<ReportData> {
        let params = new HttpParams().set("reqNo", reqNo.toString());
        const obsRet = new Observable<ReportData>(observer => {
            this.repository.getDataWithParams('api/PurchaseRequests/ReportDataForRequest', params).subscribe(result => {

                var m = result as ReportData;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public GetReportDataForRequestsList(dataTablesParam: any): Observable<ReportData> {
        dataTablesParam.length = 1000;
        const obsRet = new Observable<ReportData>(observer => {
            this.repository.getDataTablesData('api/PurchaseRequests/ReportDataForRequests', dataTablesParam).subscribe(result => {
                var m = result as ReportData;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public POReceipts(poNo: string): Observable<PoReceipt[]> {
        const obsRet = new Observable<PoReceipt[]>(observer => {
            this.repository.getData('api/PurchaseRequests/POReceipts?poNo=' + poNo).subscribe(result => {
                var m = result as PoReceipt[];
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public UpdatePOReceipts(poNo: string, receipts: PoReceipt[]): Observable<boolean> {        
        const obsRet = new Observable<boolean>(observer => {
            this.repository.create('api/PurchaseRequests/POReceipts?poNo=' + poNo, receipts).subscribe(result => {
                var m = result as boolean;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public ExportRequests(search: string, myRequestsOnly: boolean, selectedStatuses: string[]): Observable<Blob> {

        var dataTablesParameters: any = {};
        dataTablesParameters.draw = 0;
        dataTablesParameters.start = 0;
        dataTablesParameters.length = 10000;
        dataTablesParameters.columns = [];
        dataTablesParameters.search = {};
        dataTablesParameters.search.value = (search == null ? '' : search);
        if (myRequestsOnly) {
            var column: any = {};
            column.name = 'myrequests';
            column.search = {};
            column.search.value = "true";
            dataTablesParameters.columns.push(column);
        }
        if (selectedStatuses != null) {
            var column: any = {};
            column.name = 'status';
            column.search = {};
            column.search.value = selectedStatuses.join();
            dataTablesParameters.columns.push(column);
        }
        var that = this;
        const obsRet = new Observable<Blob>(observer => {
            this.repository.getFilePost('api/PurchaseRequests/ExportPurchaseRequests', dataTablesParameters).subscribe(result => {
                var newBlob = result;
                observer.next(newBlob);
                observer.complete();
            });
        });
        return obsRet;
    }

}
