import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DataTablesResponse } from '../_interfaces/data-tables-response';
import { RepositoryService } from '../_services/repository.service';
import { HttpClient } from '@angular/common/http';
import { DataTablesModule, DataTableDirective } from 'angular-datatables';
import { PurchaseRequestSearch } from '../_interfaces/purchase-request-search';
import { ActivatedRoute, Router } from '@angular/router';
import { ReqMaster } from '../_interfaces/req-master';
import { PurchaseRequestsServiceService } from '../_services/purchase-requests-service.service';
import { timer } from 'rxjs';
import { ReportData } from '../_interfaces/report-data';
import { ReportingService } from '../_services/reporting.service';
import { ApplicationService } from '../_services/application.service';
import { StatusPipe } from '../utilities/pipes//status.pipe';
import swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { headerSettings } from '../_interfaces/headerSettings';
import { AuthService } from '../auth/auth.service';
import { TabName } from '../_interfaces/tab-name';
import { PrGridColumns } from '../_interfaces/pr-grid-columns';

declare var $: any;

@Component({
    selector: 'app-purchase-requests',
    templateUrl: './purchase-requests.component.html',
    styleUrls: ['./purchase-requests.component.scss']
})



export class PurchaseRequestsComponent implements OnInit {
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    selectedPRNo = 10;
    public currentRecord = 0;
    public totalRecords = 0;
    public fromDirectURL: boolean = false;
    public openWhenDrawCompleted: boolean = false;
    public selectLastOnDrawCompleted: boolean = false;
    public headerSettings: headerSettings = {} as headerSettings;
    requestEditorVisible: any = { visible: false };
    @ViewChild('prDetailsComponent') prDetailsComponent;
    public tabName: TabName = {} as TabName;
    public myRequestsOnly: boolean = true;
    public selectedStatuses: string[] = ['Pending', 'Submitted', 'Approved', 'Pending Approval', 'Denied'];
    public allStatuses: string[] = ['Pending', 'Submitted', 'Approved', 'Pending Approval', 'Complete', 'Denied'];
    public statusPipe = new StatusPipe();
    public allColumns: PrGridColumns[];
    public selectedColumns: string[];
    elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    public loading = false;
    public data: PurchaseRequestSearch[] = [];
    dtOptions: any = {}; // removed DataTables.Settings ,buttons @types don't work despite instalation, see https://github.com/l-lin/angular-datatables/issues/1175
    dtOptions1: any = {}; // removed DataTables.Settings see comment above;
    public searchFilter: string;
    public currentDataTablesParameters: any;
    // filter result
    public filterResultCollapsed = false;

    constructor(private http: HttpClient, private repository: RepositoryService, private purchaseRequestsServiceService: PurchaseRequestsServiceService, private route: ActivatedRoute, private router: Router, private reportingService: ReportingService, private applicationService: ApplicationService, public auth: AuthService) { }

    toggleCollapsed(): void {
        this.filterResultCollapsed = !this.filterResultCollapsed;
    }

    filterById(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    hideShowColumns(): void {
        var visible = [];
        var hidden = [];
        for (var i = 0; i < this.allColumns.length; i++) {
            if (this.selectedColumns.indexOf(this.allColumns[i].value) > -1) {
                visible.push(this.allColumns[i].index);
                var vTab = this.tabName.columns.filter(t => t.columnName == this.allColumns[i].value)[0];
                vTab.hiddenYn="N"
            } else {
                //this.datatableElement.dtOptions.columns[this.allColumns[i].index].visible=false;
                hidden.push(this.allColumns[i].index);
                var vTab = this.tabName.columns.filter(t => t.columnName == this.allColumns[i].value)[0];
                vTab.hiddenYn = "Y"
            }
        }
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.columns(visible).visible(true);
        });
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.columns(hidden).visible(false);
        });
        sessionStorage.setItem("TabName", JSON.stringify(this.tabName));
        this.purchaseRequestsServiceService.UpdateTabNameForUser(this.tabName).subscribe(t => { return;});
    }


    public refreshView() {
        var that = this;
        setTimeout(function () {
            that.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
            });
        }, 50);

    }
    public clearAll() {
        this.searchFilter = '';
        this.myRequestsOnly = true;
        this.selectedStatuses = ['Pending', 'Submitted', 'Approved', 'Pending Approval', 'Denied'];
        this.filterById();
    }

    public columnIsVisible(columnName: string) {
        var col = this.tabName.columns.find(t => t.columnName == columnName);
        return col.hiddenYn == "N";
        //return true;
    }


    public ngOnInit() {
        this.allColumns = [];
        this.allColumns.push({ displayText: 'Request Date', value: 'requestDate', index: 1 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Status', value: 'status', index: 2 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Requested By', value: 'requestedByName', index: 3 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Department', value: 'departmentName', index: 4 } as PrGridColumns);
        this.allColumns.push({ displayText: 'RequestTotal', value: 'requestTotal', index: 5 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Vendor', value: 'vendorName', index: 6 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Send To', value: 'sendToName', index:7 } as PrGridColumns);
        this.allColumns.push({ displayText: 'PO Number', value: 'poNo', index: 8 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Description', value: 'description', index:9 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Buyer', value: 'buyerName', index: 10 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Due By', value: 'dueDate', index: 11 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Approved By Mgr.', value: 'approvedByMgr', index: 12 } as PrGridColumns);
        this.allColumns.push({ displayText: 'PO Status', value: 'poStatus', index: 13 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Project No.', value: 'projectNo', index: 14 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Request Type', value: 'reqType', index: 15 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Ship To', value: 'shipTo', index: 16 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Ship Via', value: 'shipVia', index: 17 } as PrGridColumns);
        this.allColumns.push({ displayText: 'Comments', value: 'comments', index: 18 } as PrGridColumns);
        if (localStorage.getItem("prHeaderSettings") != null) {
            var headerSettings = JSON.parse(localStorage.getItem("prHeaderSettings"));
            this.searchFilter = headerSettings.searchString;
            this.myRequestsOnly = headerSettings.myRequests;
            this.selectedStatuses = headerSettings.selectedStatus;
        }
        this.selectedColumns = [];
        this.tabName = JSON.parse(sessionStorage.getItem("TabName"));
        if (this.tabName.sortColumn == "reqNoShow") {
            this.tabName.sortColumn ="0";
        }
        for (var i = 0; i < this.allColumns.length; i++) {
            var col = this.tabName.columns.find(t => t.columnName == this.allColumns[i].value);
            if (col != null && col.hiddenYn == "N")
                this.selectedColumns.push(this.allColumns[i].value)
        }

        this.route.queryParamMap.subscribe(params => {
            if (params.has('id')) {
                if (params.get('id') == "0") {
                    this.NewRequest(null);
                    this.fromDirectURL = true;
                }
                else {
                    this.showPRDetails(params.get('id'));
                    this.fromDirectURL = true;
                }
            }
        });

        $.fn.dataTable.render.parens = function (cutoff) {
            return function (data, type, row) {
                if (type === 'display') {
                    var val = data.toFixed(2);
                    return val < 0 ?
                        '$(' + val.replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('-', '') + ')' :
                        '$' + val.replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }

                // Search, order and type can use the original data
                return data;
            };
        };

        const that = this;
        this.dtOptions = {
            'pagingType': 'full_numbers',
            'lengthMenu': [
                //[10, 25, 50, -1],
                //[10, 25, 50, 'All']
                [10, 25, 50, 100],
                [10, 25, 50, 100]
            ],
            dom: '<"row flex-baseline mb-2"<"col-sm-6 col-md-8 col-lg-4 mb-2 mb-sm-0"l><"col-sm-6 col-md-4 col-lg-8"B>><"dt-tableWrapper"tr><"row mt-3"<"col-sm-12 col-md-6 mb-2"i><"col-sm-12 col-md-6 mb-2"p>><"mb-3 mt-1">',
            buttons: [
                //{className: 'btn obm-button exportbutton', text: '<i class="obd-export"></i> Export'},
                {
                    text: '<i class="obd-export"></i>Export',
                    className: 'btn obm-button',
                    key: '1',
                    action: function (e, dt, node, config) {
                        that.Export();
                    }
                },
                //{className: 'btn obm-button printbutton', text: '<i class="obd-Print"></i> Print'},
                {
                    text: '<i class="obd-print"></i>Print',
                    className: 'btn obm-button',
                    key: '1',
                    action: function (e, dt, node, config) {
                        that.Print();
                    }
                },
                //{className: 'btn obm-button copybutton', text: '<i class="obd-copy"></i>Copy'  },
                {
                    text: '<i class="obd-copy"></i>Copy',
                    className: 'btn obm-button',
                    key: '1',
                    action: function (e, dt, node, config) {
                        that.Copy();
                    }
                },
                //{className: 'btn obm-button deletebutton', text: '<i class="obd-delete"></i> Delete'},
                {
                    text: '<i class="obd-delete"></i>Delete',
                    className: 'btn obm-button',
                    key: '1',
                    action: function (e, dt, node, config) {
                        that.Delete();
                    }
                }

            ],
            searching: false,
            responsive: true,
            scrollCollapse: true,
            sScrollX: true,
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'Search records',
                processing: '<div class="spinner-default"> <div class="bounce1"></div> <div class="bounce2"></div> <div class="bounce3"></div> </div>'
            },
            pageLength: 10,
            serverSide: true,
            processing: true,
            // deferLoading: 2,
            // stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {
                const apiAddress = 'api/PurchaseRequests/PurchaseRequests';
                dataTablesParameters.search.value = that.searchFilter == null ? '' : that.searchFilter;
                if (this.myRequestsOnly) {
                    var column: any = {};
                    column.name = 'myrequests';
                    column.search = {};
                    column.search.value = "true";
                    dataTablesParameters.columns.push(column);
                }
                if (this.selectedStatuses != null) {
                    var column: any = {};
                    column.name = 'status';
                    column.search = {};
                    column.search.value = this.selectedStatuses.join();
                    dataTablesParameters.columns.push(column);
                }
                this.headerSettings.myRequests = this.myRequestsOnly;
                this.headerSettings.selectedStatus = this.selectedStatuses;
                this.headerSettings.searchString = this.searchFilter;
                localStorage.setItem('prHeaderSettings', JSON.stringify(this.headerSettings));
                this.currentDataTablesParameters = dataTablesParameters;
                if (dataTablesParameters != null && dataTablesParameters.order != null && dataTablesParameters.order.length > 0) {
                    this.tabName.sortColumn = dataTablesParameters.order[0].column;
                    this.tabName.sortOrder = dataTablesParameters.order[0].dir;
                }
                this.purchaseRequestsServiceService.UpdateTabNameForUser(this.tabName).subscribe(t => { return; });
                this.repository.getDataTablesData(apiAddress, dataTablesParameters)
                    .subscribe(res => {

                        that.data = (res as DataTablesResponse).data;
                        that.totalRecords = (res as DataTablesResponse).recordsFiltered;
                        if (that.data != null && that.data.length == 0) {
                        }
                        callback({
                            recordsTotal: (res as DataTablesResponse).recordsTotal,
                            recordsFiltered: (res as DataTablesResponse).recordsFiltered,
                            data: that.data,
                        });


                    },
                        (error) => {
                            console.log(error);
                            // this.errorHandler.handleError(error);
                            // this.errorMessage = this.errorHandler.errorMessage;
                        })

            },
            columns: [
                {
                    title: 'Req No', data: 'reqNoShow', render: function (data: any) {
                        return '<a href="javascript:;" >' + data + '</a>';
                    }
                },
                {
                    title: 'Request Date', data: 'requestDate', visible: this.columnIsVisible("requestDate"), render: function (data: any) {
                        //OD-61 convert to UTC datetime so that angular can handle to local time
                        //OD-108 if pr is created in ascendance, it already has Z
                        //OD-125 request date should never be null in db, but still add a null check here in case of dirty data
                        if (data) {
                            if (data[data.length - 1] != 'Z') {
                                const date = new Date(data + 'Z');
                                return date.toLocaleDateString();
                            }
                            else {
                                const date = new Date(data);
                                return date.toLocaleDateString();
                            }
                        }
                        else {
                            //means that request date in db is null
                            return null;
                        }
                    }
                },
                { className: 'text-center', title: 'Status', data: 'status', visible: this.columnIsVisible("status"), render: (data: any) => '<span class="badge ' + this.statusPipe.transform(data) + '">' + data + '</span>' },
                { title: 'Requested By', data: 'requestedByName', visible: this.columnIsVisible("requestedByName") },
                { title: 'Department', data: 'departmentName', visible: this.columnIsVisible("departmentName") },
                { className: 'totalCol', title: 'Request Total', data: 'requestTotal', visible: this.columnIsVisible("requestTotal"), render: $.fn.dataTable.render.parens() },
                { title: 'Vendor', data: 'vendorName', visible: this.columnIsVisible("vendorName") },
                { title: 'Send To', data: 'sendToName', visible: this.columnIsVisible("sendToName") },
                { title: 'PO Number', data: 'poNo', visible: this.columnIsVisible("poNo") },
                { title: 'Description', data: 'description', visible: this.columnIsVisible("description") },
                { title: 'Buyer', data: 'buyerName', visible: this.columnIsVisible("buyerName") },
                {
                    title: 'Due By', data: 'dueDate', visible: this.columnIsVisible("dueDate"), render: function (data: any) {
                        //OD-61 convert to UTC datetime so that angular can handle to local time
                        //OD-108 if pr is created in ascendance, it already has Z
                        //OD-125 request date should never be null in db, but still add a null check here in case of dirty data
                        if (data) {
                            if (data[data.length - 1] != 'Z') {
                                const date = new Date(data + 'Z');
                                return date.toLocaleDateString();
                            }
                            else {
                                const date = new Date(data);
                                return date.toLocaleDateString();
                            }
                        }
                        else {
                            //means that request date in db is null
                            return null;
                        }
                    }
                },
                { title: 'Approved By Mgr.', data: 'approvedByManagerName', visible: this.columnIsVisible("approvedByMgr") },
                { title: 'PO Status', data: 'poStatus', visible: this.columnIsVisible("poStatus") },
                { title: 'Project No.', data: 'projectNo', visible: this.columnIsVisible("projectNo") },
                { title: 'Request Type', data: 'reqType', visible: this.columnIsVisible("reqType") },
                { title: 'Ship To', data: 'shipTo', visible: this.columnIsVisible("shipTo") },
                { title: 'Ship Via', data: 'shipVia', visible: this.columnIsVisible("shipVia") },
                { title: 'Comments', data: 'comments', visible: this.columnIsVisible("comments") },
            ],
            select: {
                style: 'single'
            },
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td:eq(0)', row).unbind('click');
                $('td:eq(0)', row).bind('click', () => {
                    this.fromDirectURL = false;
                    self.showPODetails(data);
                });


                return row;
            },
            drawCallback: () => {
                //$('.deletebutton').unbind('click');
                //$('.deletebutton').bind('click', () => {
                //  this.Delete();
                //});
                //$('.printbutton').unbind('click');
                //$('.printbutton').bind('click', () => {
                //  this.Print();
                //});
                //$('.exportbutton').unbind('click');
                //$('.exportbutton').bind('click', () => {
                //  this.Export();
                //});
                //$('.copybutton').unbind('click');
                //$('.copybutton').bind('click', () => {
                //  this.Copy();
                //});

                this.datatableElement.dtInstance.then((dtInstance: any) => {


                    if (this.openWhenDrawCompleted) {
                        if (this.selectLastOnDrawCompleted) {
                            var pagelen = dtInstance.page.len() - 1;
                            dtInstance.row(':eq(' + pagelen + ')', { page: 'all' }).select();
                            var row = dtInstance.rows({ selected: true });
                            this.showPODetails(row.data()[0]);
                            this.openWhenDrawCompleted = false;
                            this.selectLastOnDrawCompleted = false;

                        }
                        else {
                            dtInstance.row(':eq(0)', { page: 'all' }).select();
                            var row = dtInstance.rows({ selected: true });
                            this.showPODetails(row.data()[0]);
                            this.openWhenDrawCompleted = false;
                        }
                    }
                    else
                        dtInstance.row(':eq(0)', { page: 'all' }).select();
                });

            },
            order:[[this.tabName.sortColumn,this.tabName.sortOrder]]
        };

    }

    public Export() {
        this.loading = true;
        var observable = this.purchaseRequestsServiceService.ExportRequests(this.searchFilter, this.myRequestsOnly, this.selectedStatuses);
        observable.subscribe(result => {
            saveAs(result, 'Purchase Requests.xlsx', {
                type: 'application/ms-excel'
            });
            this.loading = false;

        });
    }

    public Print() {
        this.loading = true;
        this.purchaseRequestsServiceService.GetReportDataForRequestsList(this.currentDataTablesParameters).subscribe(result => {
            this.loading = false;
            var rd = result as ReportData;
            this.reportingService.ShowPurchaseRequestsListReport(rd, "Purchase Requests List", "Preview", '');
        });
    }

    public Copy() {
        var that = this;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            var selectedrequest = dtInstance.rows({ selected: true }).data()[0] as ReqMaster;
            if (selectedrequest != null) {
                that.NewRequest(selectedrequest.reqNo);
            }
            else {
                $.alert("Please select a Purchase Request to copy from!");

            }
        });
    }

    public Delete() {
        var that = this;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            var selectedrequest = dtInstance.rows({ selected: true }).data()[0] as ReqMaster;
            if (selectedrequest != null) {
                swal({
                    text: "Are sure you want to delete this item?",
                    type: 'warning',
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then((msgresult) => {
                    if (msgresult.value) {
                        that.purchaseRequestsServiceService.DeleteRequest(selectedrequest.reqNo).subscribe(result => {
                            if (result) {
                                //dtInstance.draw();
                                that.filterById();
                            }
                            else {

                            }
                        });
                    }
                })

            }

        });



    }

    RowClick(row: Node, data: any[] | Object, index: number) {


    }

    showPODetails(info: any): void {
        if (info != null && info.reqNo != undefined)
            this.showPRDetails(info.reqNo);


    }

    private showPRDetails(reqNumber: any) {

        var that = this; //
        if (this.datatableElement.dtInstance != null) {
            this.datatableElement.dtInstance.then((dtInstance: any) => {
                this.fromDirectURL = false;
                //dtInstance.row(':eq(0)', { page: 'all' }).select();
                //var count = dtInstance.rows({ selected: true }).indexes();
                var index = that.data.findIndex(r => r.reqNo == reqNumber);

                if (index >= 0) {
                    var info = dtInstance.page.info().page;
                    var pagelen = dtInstance.page.len();
                    that.currentRecord = info * pagelen + index + 1;
                }
                else {
                    that.currentRecord = 1;
                    that.totalRecords = 1;
                }

            });
        }
        else {
            that.currentRecord = 1;
            that.totalRecords = 1;
        }


        this.selectedPRNo = reqNumber;
        this.prDetailsComponent.show(reqNumber);
        this.requestEditorVisible.visible = true;
        this.elemMainPanel.scrollTop = 0; //scrollTo not working in Edge
        this.router.navigate(['purchase-requests'], { queryParams: { id: reqNumber } });


    }


    public NewRequest(copyFrom: number) {
        this.selectedPRNo = 0;
        this.prDetailsComponent.newRequest(copyFrom);
        this.requestEditorVisible.visible = true;
        this.elemMainPanel.scrollTop = 0;
    }

    public Navigate(event: any) {

        var direction = event;
        var that = this;

        this.datatableElement.dtInstance.then((dtInstance: any) => {

            var currentIndex = this.data.findIndex(r => r.reqNo == this.selectedPRNo);
            var next = currentIndex + direction;
            if (direction == 1) {
                var last_row = dtInstance.row(':last').index();
                if (last_row < next) {
                    dtInstance.page('next').draw('page');
                    next = 0;
                    this.openWhenDrawCompleted = true;
                }
                else {
                    dtInstance.row(':eq(' + next + ')', { page: 'all' }).select();
                    var row = dtInstance.rows({ selected: true });

                    this.showPODetails(row.data()[0]);
                }
            }
            else {

                if (next < 0) {

                    dtInstance.page('previous').draw('page');
                    next = 0;
                    this.openWhenDrawCompleted = true;
                    this.selectLastOnDrawCompleted = true;
                }
                else {
                    dtInstance.row(':eq(' + next + ')', { page: 'all' }).select();
                    var row = dtInstance.rows({ selected: true });

                    this.showPODetails(row.data()[0]);
                }
            }
        });
    }

}
