import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RepositoryService } from './repository.service';
import { AuthService } from '../auth/auth.service';
import { ReportData } from '../_interfaces/report-data';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private http: HttpClient, private repository: RepositoryService, private authService: AuthService) { }

  public ShowPurchaseRequestReport(reportData: ReportData, reportTitle: string, action: string, format: string) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.target = '_blank';
    
    //f.action = reportData.reportServer;// 'http://radiusdemo2:801/Report';
    document.body.appendChild(f);

    this.AddReportFormInput(f, 'ReportName', 'PurchaseRequests.rpt'); 
    this.AddReportStandardParams(f, reportData);
 
    this.AddReportFormInput(f, 'fcount', '1'); 
    this.AddReportFormInput(f, 'P0', 'Report Title'); 
    this.AddReportFormInput(f, 'V0', reportTitle != null ? reportTitle : 'Purchase Request'); 
    if (action != 'Preview') {
      this.AddReportFormInput(f, 'Action', action);
      this.AddReportFormInput(f, 'exportFormat', format);
      
    }
     
    f.submit();
    document.body.removeChild(f);
  }


  public ShowPurchaseOrderReport(reportData: ReportData, poNo: string, reportTitle: string, action: string, format: string) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.target = '_blank';

    //f.action = reportData.reportServer;// 'http://radiusdemo2:801/Report';
    document.body.appendChild(f);
    reportData.sessionId = -1;
    this.AddReportFormInput(f, 'ReportName', 'purchase_order.rpt');
    this.AddReportStandardParams(f, reportData);

    
    this.AddReportFormInput(f, 'P0', 'Report Title');
    this.AddReportFormInput(f, 'V0', reportTitle != null ? reportTitle : 'Purchase Requests List');

    if (poNo != null) {
      this.AddReportFormInput(f, 'fcount', '2');
      this.AddReportFormInput(f, 'P1', 'PONumber');
      this.AddReportFormInput(f, 'V1', poNo);
    }
    else 
      this.AddReportFormInput(f, 'fcount', '1');

    if (action != 'Preview') {
      this.AddReportFormInput(f, 'Action', action);
      this.AddReportFormInput(f, 'exportFormat', format);

    }
    f.submit();
    document.body.removeChild(f);

  }

  public ShowPurchaseRequestsListReport(reportData: ReportData, reportTitle: string, action: string, format: string) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.target = '_blank';

    //f.action = reportData.reportServer;// 'http://radiusdemo2:801/Report';
    document.body.appendChild(f);

    this.AddReportFormInput(f, 'ReportName', 'PurchaseRequestList.rpt');
    this.AddReportStandardParams(f, reportData);

    this.AddReportFormInput(f, 'fcount', '1');
    this.AddReportFormInput(f, 'P0', 'Report Title');
    this.AddReportFormInput(f, 'V0', reportTitle != null ? reportTitle:'Purchase Requests List');
    //this.AddReportFormInput(f, 'Action', 'Export'); 
    if (action != 'Preview') {
      this.AddReportFormInput(f, 'Action', action);
      this.AddReportFormInput(f, 'exportFormat', format);

    }
    f.submit();
    document.body.removeChild(f);

  }

  public ShowDeptActivityReport(reportData: ReportData, reportTitle: string, fiscalYear: number, startMonth: number, endMonth: number, userId: string, action: string, format: string) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.target = '_blank';

    //f.action = reportData.reportServer ;// 'http://radiusdemo2:801/Report';
    document.body.appendChild(f);

    this.AddReportFormInput(f, 'ReportName', 'DepartmentActivity.rpt');
    this.AddReportStandardParams(f, reportData);

    this.AddReportFormInput(f, 'fcount', '5');
    this.AddReportFormInput(f, 'P0', 'Report Title');
    this.AddReportFormInput(f, 'V0', reportTitle != null ? reportTitle : 'Department Activity');

    this.AddReportFormInput(f, 'P1', 'UserID');
    this.AddReportFormInput(f, 'V1', userId);

    this.AddReportFormInput(f, 'P2', 'Fiscal Year');
    this.AddReportFormInput(f, 'V2', fiscalYear.toString());

    this.AddReportFormInput(f, 'P3', 'Beginning Month');
    this.AddReportFormInput(f, 'V3', startMonth.toString());

    this.AddReportFormInput(f, 'P4', 'Ending Month');
    this.AddReportFormInput(f, 'V4', endMonth.toString());
    if (action != 'Preview') {
      this.AddReportFormInput(f, 'Action', action);
      this.AddReportFormInput(f, 'exportFormat', format);

    }
    //this.AddReportFormInput(f, 'Action', 'Export');

    f.submit();
    document.body.removeChild(f);
    }

    public ShowSubledgerReport(reportData: ReportData, reportTitle: string, fiscalYear: number, startMonth: number, endMonth: number, userId: string, action: string, format: string) {
        var f = document.createElement('form');
        f.method = 'POST';
        f.target = '_blank';

        //f.action = reportData.reportServer ;// 'http://radiusdemo2:801/Report';
        document.body.appendChild(f);

        this.AddReportFormInput(f, 'ReportName', 'SubledgerFInancialStatement.rpt');
        this.AddReportStandardParams(f, reportData);
        this.AddReportFormInput(f, 'fcount', '4');
        this.AddReportFormInput(f, 'P0', 'Report Title');
        this.AddReportFormInput(f, 'V0', reportTitle != null ? reportTitle : 'Sub Ledger Financial Statement');

        this.AddReportFormInput(f, 'P1', 'Fiscal Year');
        this.AddReportFormInput(f, 'V1', fiscalYear.toString());

        this.AddReportFormInput(f, 'P2', 'Beginning Month');
        this.AddReportFormInput(f, 'V2', startMonth.toString());

        this.AddReportFormInput(f, 'P3', 'Ending Month');
        this.AddReportFormInput(f, 'V3', endMonth.toString());

        //this.AddReportFormInput(f, 'P4', 'Detail');
        //this.AddReportFormInput(f, 'V4', "Yes");

        if (action != 'Preview') {
            this.AddReportFormInput(f, 'Action', action);
            this.AddReportFormInput(f, 'exportFormat', format);

        }
        //this.AddReportFormInput(f, 'Action', 'Export');

        f.submit();
        document.body.removeChild(f);
    }


  private AddReportStandardParams(f: any, reportData: ReportData) {
    f.action = reportData.reportServer;
    this.AddReportFormInput(f, 'rsid', reportData.sessionId.toString());
    this.AddReportFormInput(f, 'dbm', reportData.dbServer);
    this.AddReportFormInput(f, 'dbs', reportData.dbSid);
    this.AddReportFormInput(f, 'dbe', reportData.dbSchema);
    this.AddReportFormInput(f, 'dbu', reportData.dbSchema);
    this.AddReportFormInput(f, 'dbp', reportData.schemaToken);
    this.AddReportFormInput(f, 'schcode', reportData.schoolCode); 
  }
  private AddReportFormInput(form: any, name: string, value: string ) {
    var myInput1 = document.createElement('input');
    myInput1.setAttribute('name', name);
    myInput1.setAttribute('value', value);
    form.appendChild(myInput1);
  }

}
