﻿import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validators, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[multipleEmail][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MultipleEmailValidator), multi: true }
    ]
})

export class MultipleEmailValidator {
    validate(control: FormControl): { [key: string]: any } | null {
        if (control.value) {
            let emails: Array<string> = control.value.split(';').map(p => p.trim());
            let hasError: boolean = emails.some(p => Validators.email(new FormControl(p)) != null);
            return hasError ? { 'Email': { value: control.value } } : null;
        }
        return null;
    };
}
