export interface User {
  id: string;
  styles: number;
  entity: number;
  alternateId: string;
  name: string;
  jobTitle: string;
  supervisor: string;
  validStart: Date;
  validEnd: Date;
  email: string;
  password: string;
  ldapAuthentication: string;
  ssoEnabled: string;
  pwLastChangeDate: Date;
  pwExpirationDate: Date;
  failedLogins: number;
  locked: Date;
  lastLogin: Date;
  lastLoginApp: string;
  pwValidateNextLogin: string;
  createUserName: string;
  createDateTime: Date;
  updateUserName: string;
  updateDateTime: Date;
}

//OD-78 viewmodel for update username email modal window
export class UserNameEmail {
    userId: string;
    fullName: string;
    email: string;
}
