import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http/src/response';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  envUrl: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.envUrl = baseUrl;

  }

  public getData(route: string) {
    return this.http.get(this.createCompleteRoute(route, this.envUrl));
  }

  public getDataWithParams(route: string, params: HttpParams) {
    return this.http.get(this.createCompleteRoute(route, this.envUrl), { params: params });
  }

  public getFile(route: string, params: HttpParams) {
    //this.http.get('https://radiusdemo2.senior-systems.com/api/BudgetManagement/ExportMyAccountsToExcel', { params: params , responseType: 'blob' })
    return this.http.get(this.createCompleteRoute(route, this.envUrl), { params: params, responseType: 'blob' });

  }

  public getFilePost(route: string, body) {
    return this.http.post(this.createCompleteRoute(route, this.envUrl), body, { responseType: 'blob' });
  }

  public getDataTablesData(route: string, body) {
    return this.http.post(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }

  public create(route: string, body) {
    return this.http.post(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }

  public update(route: string, body) {
    return this.http.put(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }

  //OD-182 GetReportDataForAcctGroups will simply pass in an array
  public postWithParams(route: string, params: any) {
    return this.http.post(this.createCompleteRoute(route, this.envUrl), params);
  }

  public delete(route: string) {
    return this.http.delete(this.createCompleteRoute(route, this.envUrl));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}${route}`;
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}