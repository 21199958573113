import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from '../../auth/auth.service';
import { ApplicationService } from '../../_services/application.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NameEmailUpdateComponent } from './name-update/name-update.component';
import { UserNameEmail} from '../../_interfaces/user';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    public listname: string;
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;

    @ViewChild('navbar-cmp') button;

    constructor(location: Location, private renderer: Renderer, private element: ElementRef, private router: Router, public auth: AuthService,private modalService:NgbModal,private applicationService:ApplicationService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.listname = this.auth.getUserName();

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // Removed chart resize as we don't need it and it causes ie11 error
        // // we simulate the window Resize so the charts will get updated in realtime.
        // const simulateWindowResize = setInterval(function () {
        //     window.dispatchEvent(new Event('resize'));
        // }, 180);

        // // we stop the simulation of Window Resize after the animations are completed
        // setTimeout(function () {
        //     clearInterval(simulateWindowResize);
        // }, 1000);
    }

    isMobileMenu() {
        if (window.outerWidth < 991) {
            return false;
        }
        return true;
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
        }
        html.classList.add('nav-open');
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

        if (window.innerWidth < 991) {
            setTimeout(function () {
                if(mainPanel&&mainPanel.style) mainPanel.style.position = ''; //OD-96 when logging out and redirect to log in page the mainpanel does not exist thus the checking.
            }, 500);
        }
    }
    sidebarToggle() {
        // var toggleButton = this.toggleButton;
        // var body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    //OD-78 method to handle user name click
    public openChangeNameEmailWindow(): void {
        //schema owner does not have a record in USERS so do nothing for schema owner
        if (this.applicationService.isSchemaOwner) {
            return;
        }
        //disable backdrop click and esc button so can only close modal clicking X and cancel
        const modalConfig: NgbModalOptions = {
            backdrop: 'static',
            keyboard:false
        }
        const modalReference = this.modalService.open(NameEmailUpdateComponent, modalConfig);
        this.applicationService.GetUserNameEmail().subscribe(result => {//pass info to modal
            modalReference.componentInstance.user = result;
            modalReference.componentInstance.loading = false;
        });  

        modalReference.result.then((result) => {
            //for refreshing the name shown in navbar because getUserName reads from local storage so 
            //call authenticate to force reload local storage
            //result is true means saved successfully otherwise X clicked or cancelled or failed
            if (result) {
                let token: string = this.auth.getToken();
                this.auth.authenticateByToken(token).subscribe(result => {
                    this.listname = this.auth.getUserName();
                });
            }
        });
    }
}
