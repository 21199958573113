import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RepositoryService } from './repository.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { RecentItem } from '../_interfaces/recent-item';
import { UserKeyAccess } from '../_interfaces/user-key-access';
import { Vendor } from '../_interfaces/vendor';
import { VendorProduct } from '../_interfaces/vendor-product';
import { User, UserNameEmail } from '../_interfaces/user';
import { AuthService } from '../auth/auth.service';
import { AppVersion } from '../_interfaces/app-version';
//import { setTimeout } from 'timers';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  public userRoles: string[] = [];
  public rolesLoaded: boolean = false;
  isSchemaOwner: boolean = false;
    public currentUser: User; 
    public schoolCode: string;

  public showLogoInfo = new Subject();
  listener = this.showLogoInfo.asObservable();
  sendLogoInfo(): void {
    this.showLogoInfo.next();
  }
  constructor(private http: HttpClient, private repository: RepositoryService, private auth: AuthService) {
    this.GetUserRoles();
  }
  
  public GetRecentItemsForCurrentUser(): Observable<RecentItem[]> {
    const recentItemsObservable = new Observable<RecentItem[]>(observer => {
      this.repository.getData('api/Application/RecentItems').subscribe(result => {
        var items = result as RecentItem[];
        observer.next(items);
        observer.complete();
      });
    });
    return recentItemsObservable;
  }

  public AddPRToRecentItems(prNo: number, prNoShow: string, prDescription: string): Observable<boolean>  {
 
    var body = {
      'identity': prNo.toString(),
      'id': prNoShow + (prDescription != null ? ' - ' + prDescription :'' ),
      'type' :1
    }
    const recentItemsObservable = new Observable<boolean> (observer => {
      this.repository.create('api/Application/RecentItems', body).subscribe(result => {
         observer.next(true);
        observer.complete();
      });
    });
    return recentItemsObservable;
  }

  public AddAssetToRecentItems(assetNo: number, assetId: string, assetName: string ): Observable<boolean> {
    
    var body = {
      'identity': assetNo.toString(),
      'id': assetId + (assetName != null ? ' - ' + assetName : ''),
      'type': 2
    }
    const recentItemsObservable = new Observable<boolean>(observer => {
      this.repository.create('api/Application/RecentItems', body).subscribe(result => {
        // var items = result as boolean;
        observer.next(true);
        observer.complete();
      });
    });
    return recentItemsObservable;
  }

  public LoadUserRoles(): Observable<boolean> {
 
    this.userRoles = [];
    var ret = new Observable<boolean>(observer => {
      var that = this;

      //this.GetUser(null).subscribe(result => {
      //  this.currentUser = result;
      //});

      var keysObs = this.repository.getData('api/BudgetManagement/UserBudgetKeys');
      var schemaOwnerObs = this.repository.getData('api/BudgetManagement/IsSchemaOwner'); 
      var userObs = this.GetUser(null); 
      forkJoin([keysObs, schemaOwnerObs, userObs]).subscribe(results => {
        var m = results[0] as UserKeyAccess[];
        m.forEach(function (value) {
          that.userRoles.push(value.securityId);
        });
        this.rolesLoaded = true
        that.isSchemaOwner = results[1] as boolean;

        this.currentUser = results[2] as User;
        observer.next(true);
        observer.complete();
      });

      //this.repository.getData('api/BudgetManagement/UserBudgetKeys').subscribe(result => {
      //  var m = result as UserKeyAccess[];
      //  m.forEach(function (value) {
      //    that.userRoles.push(value.securityId);
      //  });
      //  this.rolesLoaded = true
      //  observer.next(true);
      //  observer.complete();
      //});
      //this.repository.getData('api/BudgetManagement/IsSchemaOwner').subscribe(result => {
      //  that.isSchemaOwner = result as boolean;
      // });
    });
    return ret;
  }
  public GetCurrentUser() {
    return this.currentUser;
  }

  public GetCurrentUserId() {
    return this.auth.getUserId();
  }

  

  private GetUserRoles() {
    this.userRoles = [];
    var that = this;
    this.repository.getData('api/BudgetManagement/UserBudgetKeys').subscribe(result => {
      var m = result as UserKeyAccess[];
      m.forEach(function (value) {
        that.userRoles.push(value.securityId);
      });
      this.rolesLoaded = true;
    });
    this.repository.getData('api/BudgetManagement/IsSchemaOwner').subscribe(result => {
      that.isSchemaOwner = result as boolean;
    });
    //currentUser
    this.GetUser(null).subscribe(result => {
      this.currentUser = result;
    });
  }



  public IsSchemaOwner(): boolean {
    return this.isSchemaOwner;
  }

  public IsInRole(role: string): boolean {
    //while ((!this.rolesLoaded)) {
    //  setTimeout(function () { }, 300);
    //}
      var schoolCode = localStorage.getItem('School-Code')
      if (role == "BGT SUB LEDGER FINANCIAL STATEMENT" && schoolCode != "PS") {
          return false;
      }

    return (this.IsSchemaOwner() || this.userRoles.find(r => r == role) == role);
  }

  public GetVendor(vendorId: string): Observable<Vendor> {
    //let params = new HttpParams().set("id", deptId);
    const obsRet = new Observable<Vendor>(observer => {
      this.repository.getData('api/Application/Vendors/' + vendorId).subscribe(result => {
        var m = result as Vendor;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public GetUser(userId: string): Observable<User> {
    if (userId == null)
      userId = this.auth.getUserId();
    const obsRet = new Observable<User>(observer => {
      this.repository.getData('api/Application/User/' + userId).subscribe(result => {
        var m = result as User;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public GetVendorProduct(productNo: number): Observable<VendorProduct> {
    const obsRet = new Observable<VendorProduct>(observer => {
      this.repository.getData('api/Application/VendorProduct/' + productNo.toString()).subscribe(result => {
        var m = result as VendorProduct;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public GetSystemPreferenceValue(code: string): Observable<string> {
    const obsRet = new Observable<string>(observer => {
      this.repository.getData('api/Application/SystemPreference/' + code).subscribe(result => {
        
        var m = result as string;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public GetAppVersion(): Observable<AppVersion> {
    const obsRet = new Observable<AppVersion>(observer => {
      this.repository.getData('api/Application/Version').subscribe(result => {
        var m = result as AppVersion;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
    }

    //OD-78 method for calling the api for fetching and saving username and email
    public GetUserNameEmail(): Observable<UserNameEmail> {
        let userId: string = this.auth.getUserId();
        const obsRet = new Observable<UserNameEmail>(observer => {
            this.repository.getData('api/Application/UserNameEmail/' + userId).subscribe(result => {
                var m = result as UserNameEmail;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

    public SaveUserNameEmail(userNameEmail:UserNameEmail): Observable<boolean> {
        const obsRet = new Observable<boolean>(observer => {
            this.repository.update('api/Application/UserNameEmail', userNameEmail).subscribe(result => {
                var m = result as boolean;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

}
