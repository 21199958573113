import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {
    ngOnInit() {
    }
}
