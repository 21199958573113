﻿import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { AuthService } from './auth.service';
import { PurchaseRequestsComponent } from '../purchase-requests/purchase-requests.component';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AssetsComponent } from '../assets/assets.component';

@Injectable()
export class AuthGuardService implements CanActivate, CanDeactivate<PurchaseRequestsComponent | AssetsComponent> {
    result$: Subject<boolean> = new ReplaySubject<boolean>();
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (!this.authService.isAuthenticated() && route.queryParams['token'] != null) {
            this.router.navigate(['/pages/lock'], {
                //queryParamsHandling : 'preserve'
                queryParams: {
                    return: state.url,
                    //,
                    token: route.queryParams['token']
                }
            });
        }
        else {
            if (this.authService.isAuthenticated()) {
                return true;
            } else {
                this.router.navigate(['/pages/login'], {
                    queryParams: {
                        return: state.url
                    }
                });
                return false;
            }
        }
    }

    //OD-84 handles when user is navigating away from pr/pr details page
    //should be pr details only but there's no candeactivatechild
    //so have to put candeactivate on pr page.
    //OD-83 asset is having same problem
    canDeactivate(component: PurchaseRequestsComponent | AssetsComponent, currentRoute: ActivatedRouteSnapshot): boolean{
        if (component instanceof PurchaseRequestsComponent) {
            if (!currentRoute.queryParams.id || !component.prDetailsComponent.IsChanged()) {//if route has no id it means it's at the search grid page
                return true;
            }
            else {
                return component.prDetailsComponent.canDeactivate();
            }
        }
        if (component instanceof AssetsComponent) {
            if (!currentRoute.queryParams.id || !component.assetDetailsComponent.IsChanged()) {//if route has no id it means it's at the search grid page
                return true;
            }
            else {
                return component.assetDetailsComponent.canDeactivate();
            }
        }
        else {//if not matched just pass through
            return true;
        }
    }
}