import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NameEmailUpdateComponent } from './name-update/name-update.component';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { MultipleEmailValidator } from './name-update/multiple-email.directive';

@NgModule({
    imports: [RouterModule, CommonModule, FormsModule, NgxLoadingModule.forRoot({}) ],
    declarations: [NavbarComponent, NameEmailUpdateComponent, MultipleEmailValidator ],
    exports: [NavbarComponent],
    entryComponents: [NameEmailUpdateComponent]
})

export class NavbarModule {}
