﻿import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,

    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`
              
            }
        });
      if (request.method === 'GET') {
        request = request.clone({
          headers: request.headers.set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
        });
        //return next.handle(customRequest);
      }

        return next.handle(request).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    //unauthorized, token expired 
                    this.auth.removeToken();
                    this.router.navigate(['/pages/login']); 
                }
            }
        });
    }
}