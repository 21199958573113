import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ApplicationService } from '../../../_services/application.service';
import { UserNameEmail } from '../../../_interfaces/user';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalErrorHandlerService } from '../../../_services/global-error-handler.service';

@Component({
    selector: 'name-update',
    templateUrl: 'name-update.component.html',
    styleUrls: ['./name-update.component.scss']
})

export class NameEmailUpdateComponent implements OnInit {
    @Input() public user:UserNameEmail;
    @Input() public loading: boolean;
    constructor(public activeModal: NgbActiveModal,private applicationService:ApplicationService,private errorHandlerService:GlobalErrorHandlerService) {
        this.user = new UserNameEmail();
    }
    ngOnInit() {
        this.loading = true;
    }

    SaveNameEmail(): void {
        this.loading = true;
        this.applicationService.SaveUserNameEmail(this.user).subscribe(result => {
            if (result == false) {
                let error: any = { message: "Update of Name and Email failed" };
                this.errorHandlerService.showNotification(error);
            }
            this.loading = false;
            this.activeModal.close(result);
        });
    }
}
