import { Injectable, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { async } from 'rxjs/internal/scheduler/async';
import { SeniorAuthenticationResult } from '../_interfaces/senior-authentication-result';
import { RepositoryService } from '../_services/repository.service';
import { UserKeyAccess } from '../_interfaces/user-key-access';
import { Router } from '@angular/router';
import { log } from 'util';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  http: HttpClient;
  baseUrl: string;


  public ngOnInit() {
   
  }

    constructor(http: HttpClient, private repository: RepositoryService, @Inject('BASE_URL') baseUrl: string, private router: Router) {
    this.http = http;
    this.baseUrl = baseUrl;
   }

  public getToken(): string {
   
    var auth = JSON.parse(localStorage.getItem('OBM-Auth'));
    // if (location.origin == "http://localhost:4299") {
    //  return 'aaa';
    //}
    
    if (auth == null)
      return null;
    return (auth as SeniorAuthenticationResult).authToken;
  }

  public getUserName(): string {

    var auth = JSON.parse(localStorage.getItem('OBM-Auth'));
    if (auth == null)
      this.router.navigate(['/pages/login']); 
 
    return (auth as SeniorAuthenticationResult).userName;
  } 

  public getUserId(): string {

    var auth = JSON.parse(localStorage.getItem('OBM-Auth'));
    if (auth == null)
      this.router.navigate(['/pages/login']);
 
    return (auth as SeniorAuthenticationResult).userId;
    } 

    public getTimeout(): number {

        var auth = JSON.parse(localStorage.getItem('OBM-Auth'));
        if (auth == null)
            this.router.navigate(['/pages/login']);

        return (auth as SeniorAuthenticationResult).timeout;
    } 

    public getClientTimeout(): number {

        var auth = JSON.parse(localStorage.getItem('OBM-Auth'));
        if (auth == null)
            this.router.navigate(['/pages/login']);

        return (auth as SeniorAuthenticationResult).clientTimeout;
    }

  public isAuthenticated(): boolean {
    
    var ret = false;
      const token = this.getToken();
      var tabName = sessionStorage.getItem("TabName");
     ret = (token != null && tabName!=null);
     
    return ret; //(token!=null); 
  }

  public removeToken() {
      localStorage.removeItem('OBM-Auth');
      localStorage.removeItem('headerSettings');
      localStorage.removeItem("prHeaderSettings")
  }

  public logout() {
    this.removeToken();
    this.router.navigate(['/pages/login']);

  }

  public authenticateByToken(token: string) {
     const authObservable = new Observable(observer => {

      this.http.post(this.baseUrl + 'api/Authentication/AuthenticateUserByToken?token=' + token , null, this.generateHeaders()).subscribe(result => {
        var auth = result as SeniorAuthenticationResult;
        if (auth.isAuthenticated) {
          localStorage.setItem('OBM-Auth', JSON.stringify(auth));
        }
        observer.next(result);
      }, error => console.error(error));
    });

    return authObservable;
  }

  public authenticate(user: string, password: string): Observable<any> {
     const authObservable = new Observable(observer => {

      var auth = {
        userName: user,
        password: password
      };

      this.http.post(this.baseUrl + 'api/Authentication/AuthenticateUser', auth, this.generateHeaders()).subscribe(result => {
          var auth = result as SeniorAuthenticationResult;
          localStorage.setItem('School-Code', auth.schoolCode);
        localStorage.setItem('OBM-Auth', JSON.stringify(auth));
        observer.next(result);
      }, error => console.error(error));
    });

    return authObservable;
  }
 
  private generateHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

 
}
