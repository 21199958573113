import { NgModule, ErrorHandler } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';

import { TokenInterceptor } from './auth/token.interceptor';
import { AuthGuardService } from './auth/auth-guard.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { MinusSignToParensPipe } from './_helpers/minus-sign-to-parens.pipe';
import { NgxLoadingModule, ngxLoadingAnimationTypes  } from 'ngx-loading';
import { GlobalErrorHandlerService } from './_services/global-error-handler.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment'; 
//import { AccountSearchComponent } from './utilities/lookups/account-search/account-search.component';
//import { SubledgerSearchComponent } from './utilities/lookups/subledger-search/subledger-search.component';
//import { VendorProductsSearchComponent } from './utilities/lookups/vendor-products-search/vendor-products-search.component';
//import { VendorSearchComponent } from './utilities/lookups/vendor-search/vendor-search.component';
//import { MinusSignToParensPipe } from './utilities/pipes/minus-sign-to-parens.pipe';
//import { DepartmentSearchComponent } from './utilities/lookups/department-search/department-search.component';
//import { MyDatePickerModule } from 'mydatepicker';
//import { VendorSearchComponent } from './utilities/lookups/vendor-search/vendor-search.component';


export function getBaseUrl() {
  //temp for dev only 
  if (location.origin == "http://localhost:4299") {
    //return "https://radiusdemo2.senior-systems.com/";
    return "https://localhost:44309/";
    //return "http://radiusdemo2:8092/"; 
  }
  else 
    return document.getElementsByTagName('base')[0].href;
  //return "https://localhost:44309/"
  //return "https://radiusdemo2.senior-systems.com/";
}


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    NgbModule.forRoot(),
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    NgSelectModule,
    DataTablesModule,
    //MyDatePickerModule,
        NgxLoadingModule.forRoot({}),
    MomentModule,
        NgIdleKeepaliveModule.forRoot()
    //NgMultiSelectDropDownModule.forRoot()
    // AngularMultiSelectModule,

  ],
  //exports: [
  ////  MyDatePickerModule,
  //  DepartmentSearchComponent, VendorSearchComponent
  //],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    MinusSignToParensPipe,
   // DepartmentSearchComponent, VendorSearchComponent
    //AccountSearchComponent,
   // SubledgerSearchComponent,
    //VendorProductsSearchComponent,
    //VendorSearchComponent
    
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
    , AuthGuardService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
