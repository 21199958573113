import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DataTablesResponse } from '../_interfaces/data-tables-response';
import { RepositoryService } from '../_services/repository.service';
import { HttpClient } from '@angular/common/http';
import { DataTablesModule, DataTableDirective } from 'angular-datatables';
import { PurchaseRequestSearch } from '../_interfaces/purchase-request-search';
import { ActivatedRoute, Router } from '@angular/router';
import { ReqMaster } from '../_interfaces/req-master';
import { PurchaseRequestsServiceService } from '../_services/purchase-requests-service.service';
import { timer } from 'rxjs';
import { FxAssetImport } from '../_interfaces/fx-asset-import';
import { AssetsService } from '../_services/assets.service';
import swal from 'sweetalert2';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  // requestEditorSettings: RequestEditorSettings;  
  assetEditorVisible: any = { visible: false };
  selectedRecordNo = 10;
  public currentRecord = 0;
  public totalRecords = 0;
  public fromDirectURL: boolean = false;
  public openWhenDrawCompleted: boolean = false;
  public selectLastOnDrawCompleted: boolean = false;
  //requestEditorVisible: any = { visible: false };
  //@ViewChild('prDetailsComponent') prDetailsComponent;
  public loading: boolean = false;
 

  elemMainPanel = <HTMLElement>document.querySelector('.main-panel');

  public data: FxAssetImport[] = [];
  dtOptions: any = {}; // removed DataTables.Settings ,buttons @types don't work despite instalation, see https://github.com/l-lin/angular-datatables/issues/1175
  @ViewChild('assetDetailsComponent') assetDetailsComponent;
  public searchFilter: string;

  // filter result
  public filterResultCollapsed = false;

  constructor(private http: HttpClient, private repository: RepositoryService, public assetService: AssetsService, private route: ActivatedRoute, private router: Router) { }

  toggleCollapsed(): void {
    this.filterResultCollapsed = !this.filterResultCollapsed;
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }


  public refreshView() {
    var that = this;
    setTimeout(function () {

      that.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns.adjust();
      });

    }, 50);

  }

  public clearAll() {
    this.searchFilter = '';
    this.filterById();
  }


  public ngOnInit() {
 
    this.route.queryParamMap.subscribe(params => {
       if (params.has('id')) {
        this.showAsset(params.get('id'));
        this.fromDirectURL = true;

      }
    });

      $.fn.dataTable.render.parens = function (cutoff) {
          return function (data, type, row) {
              if (type === 'display') {
                  if (data != null) {
                      var val = data.toFixed(2);
                      return val < 0 ?
                          '$(' + val.replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('-', '') + ')' :
                          '$' + val.replace(/\d(?=(\d{3})+\.)/g, '$&,');
                  } else {
                      return null;
                  }
              }

              // Search, order and type can use the original data
              return data;
          };
      };


    const that = this;
    this.dtOptions = {
      'pagingType': 'full_numbers',
      'lengthMenu': [
        //[10, 25, 50, -1],
        [10, 25, 50, 100],
        //[10, 25, 50, 'All']
        [10, 25, 50, 100]
      ],
      dom: '<"row flex-baseline mb-2"<"col-sm-6 col-md-8 col-lg-4 mb-2 mb-sm-0"l><"col-sm-6 col-md-4 col-lg-8"B>><"dt-tableWrapper"tr><"row mt-3"<"col-sm-12 col-md-6 mb-2"i><"col-sm-12 col-md-6 mb-2"p>><"mb-3 mt-1">',
      buttons: [
        //{ className: 'btn obm-button exportbutton', text: '<i class="obd-export"></i> Export', extend: 'excel' },
        {
          text: '<i class="obd-export"></i>Export',
          className: 'btn obm-button',
          key: '1',
          action: function (e, dt, node, config) {
            that.Export();
          }
        },
         //{className: 'btn obm-button deletebutton', text: '<i class="obd-delete"></i> Delete'}
        {
          text: '<i class="obd-delete"></i>Delete',
          className: 'btn obm-button',
          key: '1',
          action: function (e, dt, node, config) {
            that.Delete();
          }
        }
      ],
      searching: false,
      responsive: true,
      scrollCollapse: true,
      sScrollX: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search records',
        processing: '<div class="spinner-default"> <div class="bounce1"></div> <div class="bounce2"></div> <div class="bounce3"></div> </div>'
      },
      pageLength: 10,
      serverSide: true,
      processing: true,
      // deferLoading: 2,
      // stateSave: true,
      ajax: (dataTablesParameters: any, callback) => {
        const apiAddress = 'api/Assets/Search';
        dataTablesParameters.search.value = that.searchFilter == null ? '' : that.searchFilter;


        this.repository.getDataTablesData(apiAddress, dataTablesParameters)
          .subscribe(res => {


            that.data = (res as DataTablesResponse).data;
            that.totalRecords = (res as DataTablesResponse).recordsFiltered;
            callback({
              recordsTotal: (res as DataTablesResponse).recordsTotal,
              recordsFiltered: (res as DataTablesResponse).recordsFiltered,
              data: that.data,
            });


          },
              (error) => {
                  console.log(error);
              // this.errorHandler.handleError(error);
              // this.errorMessage = this.errorHandler.errorMessage;
            })

      },
      columns: [
        {
          title: 'ID', data: 'id', 'render': function (data, type, full, meta) {
            return '<a href="javascript:;" >' + data + '</a>';
          }
        },
        { title: 'Name', data: 'name' },
        { title: 'Vendor', data: 'vendorId' },
        { title: 'PO Number', data: 'poNo' },
        { className: 'text-right', title: 'Purchase Price', data: 'purchasePrice', render: $.fn.dataTable.render.parens() },
        {
          title: 'Purchase Date', data: 'purchaseDate', 'render': function (data, type, full, meta) {
            const date = new Date(data);
            return date.toLocaleDateString();
          }
        },
        {
          title: 'Date in Service', data: 'dateInService', 'render': function (data, type, full, meta) {
            const date = new Date(data);
            return date.toLocaleDateString();
          }
        },
 
      ],
      select: {
        style: 'single'
      },
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td:eq(0)', row).unbind('click');
        $('td:eq(0)', row).bind('click', () => {
          this.fromDirectURL = false;
          self.showAssetDetails(data);
        });


        return row;
      },
      drawCallback: () => {
        //$('.deletebutton').unbind('click');
        //$('.deletebutton').bind('click', () => {
        //  this.Delete();
        //});
        //$('.exportbutton').unbind('click');
        //$('.exportbutton').bind('click', () => {
        //  this.Export();
        //});
        this.datatableElement.dtInstance.then((dtInstance: any) => {


          if (this.openWhenDrawCompleted) {
            if (this.selectLastOnDrawCompleted) {
              var pagelen = dtInstance.page.len() - 1;
              dtInstance.row(':eq(' + pagelen + ')', { page: 'all' }).select();
              var row = dtInstance.rows({ selected: true });
              this.showAssetDetails(row.data()[0]);
              this.openWhenDrawCompleted = false;
              this.selectLastOnDrawCompleted = false;

            }
            else {
              dtInstance.row(':eq(0)', { page: 'all' }).select();
              var row = dtInstance.rows({ selected: true });
              this.showAssetDetails(row.data()[0]);
              this.openWhenDrawCompleted = false;
            }
          }
          else
            dtInstance.row(':eq(0)', { page: 'all' }).select();
        });

      }
    };

  }

  public Export() {
    this.loading= true;
    var observable = this.assetService.ExportAssets(this.searchFilter);
    observable.subscribe(result => {
      saveAs(result, 'Assets.xlsx', {
        type: 'application/ms-excel'
      });
      this.loading = false;

    });
  }

  public Delete() {
    var that = this;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var selectedrequest = dtInstance.rows({ selected: true }).data()[0] as FxAssetImport;
      if (selectedrequest != null) {

        swal({
          text: "Are sure you want to delete this item?",
          type: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((msgresult) => {
          if (msgresult.value) {
            that.assetService.DeleteAsset(selectedrequest.batchRecordNo).subscribe(result => {
              if (result) {
                that.filterById();
              }
              else {

              }
            });
          }
        })
 
      }

    });



  }

  RowClick(row: Node, data: any[] | Object, index: number) {


  }

  showAssetDetails(info: any): void {
    this.showAsset(info.batchRecordNo);

  }

  private showAsset(batchRecordNo: any) {

    var that = this; //
    if (this.datatableElement.dtInstance != null) {
      this.datatableElement.dtInstance.then((dtInstance: any) => {
        this.fromDirectURL = false;
        //dtInstance.row(':eq(0)', { page: 'all' }).select();
        //var count = dtInstance.rows({ selected: true }).indexes();
        var index = that.data.findIndex(r => r.batchRecordNo == batchRecordNo);

        if (index >= 0) {
          var info = dtInstance.page.info().page;
          var pagelen = dtInstance.page.len();
          that.currentRecord = info * pagelen + index + 1;
        }
        else {
          that.currentRecord = 1;
          that.totalRecords = 1;
        }

      });
    }
    else {
      that.currentRecord = 1;
      that.totalRecords = 1;
    }


    this.selectedRecordNo = batchRecordNo;
    this.assetDetailsComponent.show(batchRecordNo);
    this.assetEditorVisible.visible = true;
    this.elemMainPanel.scrollTop = 0; //scrollTo not working in Edge
    this.router.navigate(['assets'], { queryParams: { id: batchRecordNo } });


  }


  public NewRequest() {
    //newRequest
    //this.selectedPRNo = 0;
    //this.prDetailsComponent.newRequest();
    //this.requestEditorVisible.visible = true;
    //this.elemMainPanel.scrollTop = 0; //scrollTo not working in Edge
  }

  public Navigate(event: any) {

    var direction = event;
    var that = this;

    this.datatableElement.dtInstance.then((dtInstance: any) => {

      var currentIndex = this.data.findIndex(r => r.batchRecordNo == this.selectedRecordNo);
      var next = currentIndex + direction;
      if (direction == 1) {
        var last_row = dtInstance.row(':last').index();
        if (last_row < next) {
          dtInstance.page('next').draw('page');
          next = 0;
          this.openWhenDrawCompleted = true;
        }
        else {
          dtInstance.row(':eq(' + next + ')', { page: 'all' }).select();
          var row = dtInstance.rows({ selected: true });

          this.showAssetDetails(row.data()[0]);
        }
      }
      else {

        if (next < 0) {

          dtInstance.page('previous').draw('page');
          next = 0;
          this.openWhenDrawCompleted = true;
          this.selectLastOnDrawCompleted = true;
        }
        else {
          dtInstance.row(':eq(' + next + ')', { page: 'all' }).select();
          var row = dtInstance.rows({ selected: true });

          this.showAssetDetails(row.data()[0]);
        }
      }
    });
  }

}
