import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RepositoryService } from './repository.service';
import { FxAssetImport } from '../_interfaces/fx-asset-import';
import { Observable } from 'rxjs';
import { FxLocation } from '../_interfaces/fx-location';
import { FxClass } from '../_interfaces/fx-class';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private http: HttpClient, private repository: RepositoryService) {
  }

  public GetAsset(recordNo: number): Observable<FxAssetImport> {
     
    const obsRet = new Observable<FxAssetImport>(observer => {
      this.repository.getData('api/Assets/' + recordNo.toString()).subscribe(result => {
        var m = result as FxAssetImport;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public UpdateAsset(asset: FxAssetImport): Observable<boolean> {
    const obsRet = new Observable<boolean>(observer => {
      this.repository.update('api/Assets', asset).subscribe(result => {
        var m = result as boolean;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public DeleteAsset(batchRecordNo: number): Observable<boolean> {
    const obsRet = new Observable<boolean>(observer => {
      this.repository.delete('api/Assets/' + batchRecordNo.toString()).subscribe(result => {
        var m = result as boolean;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public GetAssetLocations(): Observable<FxLocation[]> {
    const obsRet = new Observable<FxLocation[]>(observer => {
      this.repository.getData('api/Assets/AssetLocations').subscribe(result => {
        var m = result as FxLocation[];
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public GetAssetClasses(): Observable<FxClass[]> {
    const obsRet = new Observable<FxClass[]>(observer => {
      this.repository.getData('api/Assets/AssetClasses').subscribe(result => {
        var m = result as FxClass[];
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public ExportAssets(search: string): Observable<Blob> {
    if (search == null)
      search = "";
    var dataTablesParameters: any = {};
    dataTablesParameters.draw = 0;
    dataTablesParameters.start = 0;
    dataTablesParameters.length = 1000;
    dataTablesParameters.columns = [];
    dataTablesParameters.search = {};
    dataTablesParameters.search.value = search;

    var that = this;
    const obsRet = new Observable<Blob>(observer => {
      this.repository.getFilePost('api/Assets/ExportAssets', dataTablesParameters).subscribe(result => {
        var newBlob = result;
        observer.next(newBlob);
        observer.complete();
      });
    });
    return obsRet;
  }

}
